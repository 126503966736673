import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import Create from './Create'
import LinkIcon from '@material-ui/icons/Link'

const CompanyDepartmentAssignations = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='company_department_assignations'
    icon={LinkIcon}
    list={List}
    create={Create}
    edit={Edit}
    show={null}
  />
)

export default CompanyDepartmentAssignations

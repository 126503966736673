import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Show from './Show'
import MapIcon from '@material-ui/icons/Map'

const Department = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name={'departments'}
    icon={MapIcon}
    create={null}
    list={List}
    edit={null}
    show={Show}
  />
)

export default Department

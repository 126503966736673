const MONDAY = 'monday'
const TUESDAY = 'tuesday'
const WEDNESDAY = 'wednesday'
const THURSDAY = 'thursday'
const FRIDAY = 'friday'
const SATURDAY = 'saturday'
const SUNDAY = 'sunday'

export const DAYS = {
    [MONDAY]: 'Lundi',
    [TUESDAY]: 'Mardi',
    [WEDNESDAY]: 'Mercredi',
    [THURSDAY]: 'Jeudi',
    [FRIDAY]: 'Vendredi',
    [SATURDAY]: 'Samedi',
    [SUNDAY]: 'Dimanche',
}

export const daysList = [
    {id: MONDAY, name: DAYS[MONDAY]},
    {id: TUESDAY, name: DAYS[TUESDAY]},
    {id: WEDNESDAY, name: DAYS[WEDNESDAY]},
    {id: THURSDAY, name: DAYS[THURSDAY]},
    {id: FRIDAY, name: DAYS[FRIDAY]},
    {id: SATURDAY, name: DAYS[SATURDAY]},
    {id: SUNDAY, name: DAYS[SUNDAY]},
]

import React from 'react'
import { NumberInput } from 'react-admin'

const CoordinatesInput = (props) => (
  <div {...props}>
    <NumberInput
      source='localisation.latitude'
      step={0.001}
    />
    <NumberInput
      source='localisation.longitude'
      step={0.001}
    />
  </div>
)

export default CoordinatesInput

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import Create from './Create'
import SupervisorAccountIcon from '@material-ui/icons/DeviceHub'

const Hub = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='hubs'
    icon={SupervisorAccountIcon}
    list={List}
    create={Create}
    edit={Edit}
    show={null}
  />
)

export default Hub

import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'

const Edit = ({ ...props }) => (
  <EditGuesser {...props}>
    {/* <InputGuesser source={'pickupEvents'} /> */}
    {/* <InputGuesser source={'pickupUnexpectedHandlingEvent'} /> */}
    <InputGuesser source={'name'} />
    <InputGuesser source={'nameForCourier'} />
    <InputGuesser source={'openProposalPrice'} />
    <InputGuesser source={'realPrice'} />
    {/* <InputGuesser source={'merchandise'} /> */}
    <InputGuesser source={'vehicleType'} />
    <InputGuesser source={'vehicleLoadType'} />
    <InputGuesser source={'transportRules'} />
    <InputGuesser source={'estimatedDuration'} />
    <InputGuesser source={'mileage'} />
    <InputGuesser source={'options'} />
    {/* <InputGuesser source={'pickupWaypoints'} format={obj => obj[0].address} parse={str => ([{ address: str }])} /> */}
    {/* <InputGuesser source={'receiptWaypoints'} format={obj => obj[0].address} parse={str => ([{ address: str }])} /> */}
    <InputGuesser source={'receiptComment'} />
  </EditGuesser>
)

export default Edit

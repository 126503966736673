import React from 'react'
import { SelectInput } from 'react-admin'

const PriceSpecificationProposalInput = (props) => (
  <SelectInput
    {...props}
    source='proposal'
    choices={[
      { id: 'ecoFriendly', name: 'Écologique' },
      { id: 'fast', name: 'Rapide' },
      { id: 'all', name: 'Toutes' },
    ]}
  />
)

export default PriceSpecificationProposalInput

import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import { BooleanInput, required } from 'react-admin'

const EditCompanyCustomPage = ({ ...props }) => (
  <EditGuesser {...props} resource={'custom_pages'}>
    <BooleanInput source={'enabled'} label={"Activé ?"} />
    <InputGuesser source={'slug'} label={"Sous-domaine"} validate={[required()]} fullWidth/>
  </EditGuesser>
)

export default EditCompanyCustomPage

import { createMuiTheme } from '@material-ui/core/styles'
import { defaultTheme } from 'react-admin'

const theme = createMuiTheme({
  ...defaultTheme,
  overrides: {
    MuiTableCell: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
})

export default theme

import React from 'react'

const CoordinatesField = ({ record, source, ...props }) => {
  let latitude = record[source].coordinates.latitude || 0
  let longitude = record[source].coordinates.longitude || 0

  let link = null

  let coordinates = `${latitude.toFixed(2)},${longitude.toFixed(2)}`

  if (latitude !== 0 && longitude !== 0) {
    link = `https://www.google.com/maps/search/${latitude},${longitude}`

    return (
      <a href={link} target={'_blank'} rel={'noreferrer'}>
        {coordinates}
      </a>
    )
  }

  return (<span>{coordinates}</span>)
}

export default CoordinatesField

import React from 'react'
import { TextInput } from 'react-admin'

const AddressFullInput = (props) => (
  <div {...props}>
    <TextInput
      source='address.streetAddress'
    />
    <TextInput
      source='address.postalCode'
    />
    <TextInput
      source='address.addressLocality'
    />
  </div>
)

export default AddressFullInput

import React from 'react'
import {ListGuesser, FieldGuesser} from '@api-platform/admin'
import {Pagination, ReferenceField, TextField} from 'react-admin'
import PriceField from '../../components/PriceField'
import PriceSpecificationProposalField from '../../components/PriceSpecificationProposalField'

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({...props}) => (
    <ListGuesser {...props} perPage={250} pagination={<PostPagination/>} sort={{field: 'id', order: 'DESC'}}>
        <FieldGuesser source={'name'}/>
        <FieldGuesser source={'description'}/>

        <FieldGuesser source={'allowance1MaxMileage'} label={"Dis. 1 km max"}/>
        <PriceField source={'allowance1FlatPrice'} label={"Dis. 1 montant"}/>

        <FieldGuesser source={'allowance2MaxMileage'} label={"Dis. 2 km max"}/>
        <PriceField source={'allowance2FlatPrice'} label={"Dis. 2 montant"}/>

        <FieldGuesser source={'allowance3MaxMileage'} label={"Dis. 3 km max"}/>
        <PriceField source={'allowance3FlatPrice'} label={"Dis. 3 montant"}/>

        <FieldGuesser source={'allowance4MaxMileage'} label={"Dis. moyenne km max"}/>
        <FieldGuesser source={'allowance4Rate'} label={"Dis. moyenne taux km"}/>

        <FieldGuesser source={'allowance5MaxMileage'} label={"Longue dis. km max"}/>
        <PriceField source={'allowance5FlatPrice'} label={"Longue dis. montant"}/>

        <FieldGuesser source={'allowance6Rate'} label={"Très longue dis. taux km"}/>
        <PriceField source={'allowance6CeilingPrice'} label={"Très longue dis. montant plafond"}/>

        <PriceField source={'flatPrice'} label={"Forfait fixe"}/>

        <FieldGuesser source={'pickupTimeLimit'} label={"Temps limite pour prix garanti (min)"}/>

        <FieldGuesser source={'tailgateGuaranteed'} label={"Prix garanti avec hayon"}/>

        <FieldGuesser source={'notWorkingDayRate'} label={"Taux jours non ouvrés"}/>

        <ReferenceField label="Départements soirée" source="eveningDepartment" reference="zones">
          <TextField source="name" />
        </ReferenceField>

        <PriceSpecificationProposalField source={'proposal'} label={"Proposition"}/>

        <FieldGuesser source={'fastProposalShortRangeRate'} label={"Proposition rapide : taux entre Dis. 3 et Longue dis."}/>
        <FieldGuesser source={'fastProposalLongRangeRate'} label={"Proposition rapide : taux pour Longue dis. et +"}/>
    </ListGuesser>
)

export default List

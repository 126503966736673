import React from 'react'
import { SelectInput } from 'react-admin'

const LoadingInput = (props) => (
  <SelectInput
    {...props}
    source='loading'
    choices={[
      { id: 'rear', name: 'Rear' },
      { id: 'side', name: 'Side' },
      { id: 'any', name: 'Any' }
    ]}
  />
)

export default LoadingInput

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import StarIcon from '@material-ui/icons/Star'

const Deliveries = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='delivery_templates'
    icon={StarIcon}
    list={List}
  />
)

export default Deliveries

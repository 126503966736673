import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const Manager = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='managers'
    icon={SupervisorAccountIcon}
    list={List}
    edit={Edit}
  />
)

export default Manager

import React from 'react'
import {FieldGuesser, ListGuesser} from '@api-platform/admin'
import {Pagination} from 'react-admin'
import TruncatedTextField from '../../components/TruncatedTextField'

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({...props}) => (
    <ListGuesser {...props} perPage={250} pagination={<PostPagination/>} sort={{field: 'id', order: 'DESC'}}>
        <FieldGuesser source={'name'} label={"Nom"}/>
        <TruncatedTextField source={'values'} label={"Valeurs"}/>
    </ListGuesser>
)

export default List

import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { Filter, Pagination, TextField, TextInput } from 'react-admin'
import AddressFullField from "../../components/AddressFullField";

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const HubFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<PostPagination />} filters={<HubFilter />} sort={{ field: 'id', order: 'DESC' }}>
    <FieldGuesser source={'name'} label="Nom" />
    <FieldGuesser source={'code'} label="Code" />
    <AddressFullField source={'address'} label="Adresse"/>
    <TextField source={'departmentId'} label={'Département'}/>
  </ListGuesser>
)

export default List

import React from 'react'
import { useCallback } from 'react';
import {EditGuesser, InputGuesser} from '@api-platform/admin'
import { DateTimeInput, SelectArrayInput, SelectInput, TextInput, useMutation } from 'react-admin'
import VehicleTypeInput from "../../components/VehicleTypeInput";
import * as rideType from "../../constants/rideType";
import * as days from "../../constants/days";
import AddressHelperText from "../../components/AddressHelperText";
import RideDaysHelperText from "../../components/RideDaysHelperText";

const Edit = ({ ...props }) => {
  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      // remove the “googlePlaceId” that existed previously
      values.start.googlePlaceId = null
      values.finish.googlePlaceId = null
      try {
        await mutate({
          type: 'update',
          resource: 'rides',
          payload: { data: values },
        }, { returnPromise: true });
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );

  return(
    <EditGuesser {...props} save={save}>
      <InputGuesser source={'name'} label={"Nom"} />
      <VehicleTypeInput label={"Type de véhicule"} />
      <DateTimeInput source={'start.fromDatetime'} label={"Date et heure de départ"} />
      <TextInput source={'start.address'} fullWidth={true} label={"Adresse de départ"} helperText={AddressHelperText} />
      <TextInput source={'finish.address'} fullWidth={true} label={"Adresse d'arrivée"} helperText={AddressHelperText} />
      <SelectInput
        source='rideType'
        choices={rideType.rideTypesList}
      />
      <SelectArrayInput
        source='options'
        choices={[
          { id: 'tailgate', name: 'Hayon' }
        ]}
        label={"Options"}
      />
      <SelectArrayInput
        source='days'
        choices={days.daysList}
        label={"Jours"}
        helperText={RideDaysHelperText}
      />
    </EditGuesser>
  )
}

export default Edit

import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import AccountStatusInput from '../../components/AccountStatusInput'
import ReferenceInput from '../../components/ReferenceInput'

const Edit = ({ ...props }) => (
  <EditGuesser {...props}>
    <InputGuesser source={'firstname'} />
    <InputGuesser source={'lastname'} />
    <InputGuesser source={'email'} />
    <InputGuesser source={'phoneNumber'} />
    <InputGuesser source={'termsOfUseAccepted'} />
    <InputGuesser source={'emailConfirmed'} />
    <AccountStatusInput source={'accountStatus'} />
    <InputGuesser source={'sendNewDeliveryEmail'} />
    <ReferenceInput source={'managedCompany'} reference='companies' />
  </EditGuesser>
)

export default Edit

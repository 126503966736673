import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { ReferenceField, Pagination } from 'react-admin'
import UserField from '../../components/UserField'
// import WaypointField from '../../components/WaypointField'
import PriceField from '../../components/PriceField'
import IdField from '../../components/IdField'

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<PostPagination />} sort={{ field: 'id', order: 'DESC' }}>
    <IdField source={'id'} />
    <FieldGuesser source={'name'} />
    <FieldGuesser source={'nameForCourier'} />
    <FieldGuesser source={'openProposalPrice'} />
    <PriceField source={'realPrice'} />
    <FieldGuesser source={'state'} />
    <FieldGuesser source={'vehicleType'} />
    <FieldGuesser source={'vehicleLoadType'} />
    <ReferenceField source={'freightForwarder'} reference='freight_forwarders'>
      <UserField />
    </ReferenceField>
    <ReferenceField source={'companyCourierStart'} reference='company_couriers'>
      <UserField />
    </ReferenceField>
    {/*<WaypointField source={'pickupWaypoints'} />*/}
    {/*<WaypointField source={'receiptWaypoints'} />*/}
    <FieldGuesser source={'createdAt'} />
  </ListGuesser>
)

export default List

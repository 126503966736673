import React from 'react'
import {CreateGuesser} from '@api-platform/admin'
import {AutocompleteInput, DateTimeInput, SelectArrayInput, SelectInput, TextInput} from 'react-admin'
import VehicleTypeInput from "../../components/VehicleTypeInput";
import * as rideType from "../../constants/rideType";
import * as days from "../../constants/days";
import ReferenceInput from "../../components/ReferenceInput";
import FilterCompaniesByName from "../../components/filters/FilterCompaniesByName";
import AddressHelperText from '../../components/AddressHelperText'
import RideDaysHelperText from "../../components/RideDaysHelperText";

const Create = ({...props}) => (
    <CreateGuesser {...props}>
      <ReferenceInput label="Compagnie" source="company" reference="companies"
                      filterToQuery={FilterCompaniesByName} allowEmpty alwaysOn>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
        <VehicleTypeInput label={"Type de véhicule"}/>
        <DateTimeInput source={'start.fromDatetime'} label={"Date et heure de départ"}/>
        <TextInput source={'start.address'} fullWidth={true} label={"Adresse de départ"} helperText={AddressHelperText}/>
        <TextInput source={'finish.address'} fullWidth={true} label={"Adresse d'arrivée"} helperText={AddressHelperText}/>
        <SelectInput
            source='rideType'
            choices={rideType.rideTypesList}
        />
        <SelectArrayInput
            source='options'
            choices={[
                {id: 'tailgate', name: 'Hayon'}
            ]}
            label={"Options"}
        />
        <SelectArrayInput
            source='days'
            choices={days.daysList}
            label={"Jours"}
            helperText={RideDaysHelperText}
        />
    </CreateGuesser>
)

export default Create

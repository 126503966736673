import React from 'react'
import { CreateGuesser } from '@api-platform/admin'
import { AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';
import AddressFullInput from "../../components/AddressFullInput";
import CoordinatesInput from "../../components/CoordinatesInput";

const filterToQuery = searchText => ({ departmentName: `${searchText}` });

const Edit = ({ ...props }) => (
  <CreateGuesser {...props}>
    <TextInput source={'name'} />
    <TextInput source={'code'} />
    <AddressFullInput source={'address'} />
    <CoordinatesInput source={'localisation'} />
    <TextInput source={'email'} />
    <TextInput source={'phoneNumber'} />
    <ReferenceInput label="Département" source="departmentId" reference="departments" filterToQuery={filterToQuery}>
      <AutocompleteInput optionText="departmentName" />
    </ReferenceInput>
  </CreateGuesser>
)

export default Edit

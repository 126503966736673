import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Create from './Create'
import Edit from './Edit'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'

const Tender = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='tenders'
    icon={ShoppingBasketIcon}
    list={List}
    create={Create}
    edit={Edit}
  />
)

export default Tender

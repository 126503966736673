import React from 'react'
import { SelectArrayInput } from 'react-admin'

const OptionsInput = (props) => (
  <SelectArrayInput
    {...props}
    source='options'
    choices={[
      { id: 'refrigerated', name: 'Refrigerated' },
      { id: 'tailgate', name: 'Tailgate' }
    ]}
  />
)

export default OptionsInput

import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { BooleanField, Pagination, ReferenceField, TextField } from 'react-admin'
import IdField from '../../components/IdField'

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<PostPagination />} sort={{ field: 'id', order: 'DESC' }}>
    <IdField source={'id'} />
    <BooleanField source={'enabled'} />
    <FieldGuesser source={'slug'} />

    <ReferenceField label="Entreprise" source="company" reference="companies">
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
)

export default List

import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { BooleanInput, AutocompleteInput, Filter, ReferenceField, TextField, Pagination, TextInput, ReferenceInput, SelectInput } from 'react-admin'
import IdField from '../../components/IdField'
import FilterCompaniesByName from "../../components/filters/FilterCompaniesByName";

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Firstname" source="firstname" alwaysOn />
        <TextInput label="Lastname" source="lastname" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
        <TextInput label="Phonenumber" source="phoneNumber" alwaysOn />

        <ReferenceInput label="Managed company" source="managedCompany" reference="companies"
                        filterToQuery={FilterCompaniesByName} allowEmpty alwaysOn>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Courier of company" source="courierOfCompany" reference="companies"
                        filterToQuery={FilterCompaniesByName} allowEmpty alwaysOn>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <SelectInput label="Account status" source="accountStatus" choices={[
            { id: 'accepted', name: 'accepted' },
            { id: 'pending', name: 'pending' },
            { id: 'rejected', name: 'rejected' },
            { id: 'disabled', name: 'disabled' },
        ]} alwaysOn />
        <BooleanInput label="Account Validation Requested" source="accountValidationRequested" />
    </Filter>
);

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<PostPagination />} filters={<UserFilter />}  sort={{ field: 'id', order: 'DESC' }}>
    <IdField source={'id'} />
    <FieldGuesser source={'firstname'} />
    <FieldGuesser source={'lastname'} />
    <FieldGuesser source={'email'} />
    <FieldGuesser source={'phoneNumber'} />
    <ReferenceField source={'managedCompany'} reference='companies'>
      <TextField source='name' />
    </ReferenceField>
    <FieldGuesser source={'accountStatus'} />
    <FieldGuesser source={'createdAt'} />
    <FieldGuesser source={'updatedAt'} />
  </ListGuesser>
)

export default List

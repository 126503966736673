import React from 'react'
import {ShowGuesser} from '@api-platform/admin'
import { SimpleShowLayout, TextField, NumberField, ReferenceArrayField, ReferenceField, Datagrid } from 'react-admin'

const Show = ({...props}) => (
    <ShowGuesser {...props} resource={"company_department_assignations"} >
        <SimpleShowLayout>
            <TextField source={'departmentName'} label={"Nom"}/>
            <TextField source={'departmentCode'} label={"Code"}/>
            <ReferenceArrayField source='assignations' reference='company_department_assignations' label='Assignations'>
                <Datagrid>
                    <ReferenceField source="company" reference="companies" label="Compagnie">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="meanPrice" options={{ style: 'currency', currency: 'EUR' }} label="Coût moyen" />
              </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </ShowGuesser>
)

export default Show

import React from 'react'
import {CreateGuesser, InputGuesser} from '@api-platform/admin'
import {TextInput } from 'react-admin'
import ReferenceInput from "../../components/ReferenceInput";

const Create = ({...props}) => (
    <CreateGuesser {...props}>
        <InputGuesser source={'name'} label={'Nom'} />
        <ReferenceInput source={'priceSpecificationT1'} reference='price_specifications' label={'Grille tarifaire T1'} />
        <ReferenceInput source={'priceSpecificationT2'} reference='price_specifications' label={'Grille tarifaire T2'} />
        <ReferenceInput source={'priceSpecificationT3'} reference='price_specifications' label={'Grille tarifaire T3'} />
        <TextInput source={'departments'} multiline={true} fullWidth={true} label={'Départements'} />
    </CreateGuesser>
)

export default Create

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import PeopleIcon from '@material-ui/icons/People'

const Users = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='users'
    icon={PeopleIcon}
    list={List}
    edit={Edit}
  />
)

export default Users

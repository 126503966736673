import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { Pagination, TextField, ReferenceField } from 'react-admin';

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<PostPagination />} sort={{ field: 'id', order: 'DESC' }}>
    <ReferenceField source="company" reference="companies" label="Compagnie">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="department" reference="departments" label="Département">
      <TextField source="departmentName" />
    </ReferenceField>
    <FieldGuesser source={"meanPrice"} label="Coût moyen" />
  </ListGuesser>
)

export default List

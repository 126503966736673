import React from 'react'
import { NumberInput } from 'react-admin'

const PriceInput = (props) => (
  <NumberInput
    {...props}
    format={v => (v ? (parseInt(v, 10) / 100) : null)}
    parse={v => (v ? Math.round(v * 100) : null)}
    step={0.01}
  />
)

export default PriceInput

import React from 'react'

const AddressFullField = ({ record, source, ...props }) => (
    <span>
        {record[source].streetAddress}<br />
        {record[source].postalCode} {record[source].addressLocality}<br />
    </span>
)

export default AddressFullField

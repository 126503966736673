import jwtDecode from 'jwt-decode'

const auth = {
  login: async ({ username, password }) => {
    const body = new FormData()
    body.append('_username', username)
    body.append('_password', password)

    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/api/login`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: body,
    })

    if (!res.ok) {
      throw new Error(res.statusText)
    }

    const raw = await res.json()
    const payload = jwtDecode(raw.token)

    if (!(payload && payload.roles) || [].includes('ROLE_ADMIN')) {
      throw new Error('Only admin are allowed to connect to this app !')
    }

    localStorage.setItem('token', raw.token)
  },
  logout: async () => {
    localStorage.removeItem('token')
  },
  checkAuth: async () => {
    try {
      if (!localStorage.getItem('token')) {
        throw new Error()
      }
    } catch (e) {
      // override possible jwtDecode error
      throw new Error()
    }
  },
  checkError: async (err) => {
    if ([401, 403].includes((err || {}).status || ((err || {}).response || {}).status)) {
      localStorage.removeItem('token')
      throw new Error()
    }
  },
  getPermissions: async () => {
    return []
  },
  getIdentity: async () => {
    return {}
  },
}

export default auth

import React from 'react'
import { SelectInput } from 'react-admin'

const AccountStatusInput = (props) => (
  <SelectInput
    {...props}
    source='accountStatus'
    choices={[
      { id: 'pending', name: 'pending' },
      { id: 'rejected', name: 'rejected' },
      { id: 'accepted', name: 'accepted' },
      { id: 'disabled', name: 'disabled' },
    ]}
  />
)

export default AccountStatusInput

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'

const Deliveries = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='deliveries'
    icon={FlightTakeoffIcon}
    list={List}
    edit={Edit}
  />
)

export default Deliveries

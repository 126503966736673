import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Create from './Create'
import Edit from './Edit'
import EuroSharpIcon from '@material-ui/icons/EuroSharp'

const PriceSpecifications = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='price_specifications'
    icon={EuroSharpIcon}
    list={List}
    create={Create}
    edit={Edit}
  />
)

export default PriceSpecifications

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import BusinessIcon from '@material-ui/icons/Business'

const Companies = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='companies'
    icon={BusinessIcon}
    create={null}
    list={List}
    edit={Edit}
  />
)

export default Companies

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import CreateCompanyCustomPage from './CreateCompanyCustomPage'
import List from './List'
import EditCompanyCustomPage from './EditCompanyCustomPage'
import StoreIcon from '@material-ui/icons/Store'

const CompaniesCustomPages = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='custom_pages'
    icon={StoreIcon}
    create={CreateCompanyCustomPage}
    list={List}
    edit={EditCompanyCustomPage}
  />
)

export default CompaniesCustomPages

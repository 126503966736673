import React from 'react'
import { SelectInput } from 'react-admin'

const TailgateGuaranteedInput = (props) => (
  <SelectInput
    {...props}
    source='tailgateGuaranteed'
    choices={[
      { id: 'none', name: 'Pas de tarif garanti avec hayon' },
      { id: 'limited', name: 'Autoriser le tarif garanti avec hayon en-dessous des 300km' },
      { id: 'full', name: 'Toujours autoriser le tarif garanti avec hayon' },
    ]}
  />
)

export default TailgateGuaranteedInput

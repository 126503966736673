import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

const CompanyCouriers = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='company_couriers'
    icon={AssignmentIndIcon}
    list={List}
    edit={Edit}
  />
)

export default CompanyCouriers

import React from 'react'
import { EditGuesser } from '@api-platform/admin'
import AddressFullInput from "../../components/AddressFullInput";
import CoordinatesInput from "../../components/CoordinatesInput";
import { TextInput } from 'react-admin';

const Edit = ({ ...props }) => (
  <EditGuesser {...props}>
    <TextInput source={'name'} />
    <TextInput source={'code'} />
    <AddressFullInput source={'address'} />
    <CoordinatesInput source={'address'} />
    <TextInput source={'email'} />
    <TextInput source={'phoneNumber'} />
  </EditGuesser>
)

export default Edit

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { hydraDataProvider, fetchHydra as baseFetchHydra, useIntrospection } from '@api-platform/admin'
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation'

const getHeaders = () => ({
  ...(localStorage.getItem('token') ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {}),
})

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: getHeaders,
})

const RedirectToLogin = () => {
  const introspect = useIntrospection()

  if (localStorage.getItem('token')) {
    introspect()
    return <></>
  }
  return <Redirect to='/login' />
}

const apiDocumentationParser = async (entrypoint) => {
  try {
    const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders })
    return { api }
  } catch (res) {
    if (res.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem('token')

      return {
        api: res.api,
        customRoutes: [
          <Route key='/' path='/' component={RedirectToLogin} />,
        ],
      }
    }

    throw res
  }
}

const dataProvider = hydraDataProvider(`${process.env.REACT_APP_API_SERVER}/api`, fetchHydra, apiDocumentationParser)

export default dataProvider

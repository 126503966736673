import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { BooleanField, Pagination, ReferenceField, TextField } from 'react-admin'
import IdField from '../../components/IdField'

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<PostPagination />} sort={{ field: 'id', order: 'DESC' }}>
    <IdField source={'id'} />
    <FieldGuesser source={'name'} />
    <FieldGuesser source={'vatIdentificationNumber'} />
    <FieldGuesser source={'streetAddress'} />
    <FieldGuesser source={'additionalAddress'} />
    <FieldGuesser source={'postalCode'} />
    <FieldGuesser source={'city'} />
    <FieldGuesser source={'country'} />
    <FieldGuesser source={'sponsorshipCode'} />
    <BooleanField source={'isNew'} />

    <ReferenceField label="Grille tarifaire T1" source="priceSpecificationT1" reference="price_specifications">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Grille tarifaire T2" source="priceSpecificationT2" reference="price_specifications">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Grille tarifaire T3" source="priceSpecificationT3" reference="price_specifications">
      <TextField source='name' />
    </ReferenceField>
    <ReferenceField label="Formulaire personnalisé" source="customPage" reference="custom_pages">
      <TextField source='slug' />
    </ReferenceField>
  </ListGuesser>
)

export default List

import React from 'react'
import {ListGuesser, FieldGuesser} from '@api-platform/admin'
import { Pagination, ReferenceField, TextField} from 'react-admin'
import TruncatedTextField from '../../components/TruncatedTextField'
import IdField from "../../components/IdField";

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const List = ({...props}) => (
    <ListGuesser {...props} perPage={250} pagination={<PostPagination/>} sort={{field: 'id', order: 'DESC'}}>
        <IdField source={'id'} />
        <FieldGuesser source={'name'} />

        <ReferenceField label="Grille tarifaire T1" source="priceSpecificationT1" reference="price_specifications">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Grille tarifaire T2" source="priceSpecificationT2" reference="price_specifications">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Grille tarifaire T3" source="priceSpecificationT3" reference="price_specifications">
            <TextField source='name' />
        </ReferenceField>

        <TruncatedTextField source={'departments'} label={"Départements"}/>
    </ListGuesser>
)

export default List

import React from 'react'
import { ArrayField, SingleFieldList, ChipField } from 'react-admin'
import StringToLabelObject from './helpers/StringToLabelObject'
import { makeStyles } from '@material-ui/core'

const ArrayStringField = ({ ...props }) => (
  <ArrayField {...props}>
    <SingleFieldList linkType={false} classes={ArrayStringField.styles.SingleFieldList()}>
      <StringToLabelObject>
        <ChipField size='small' source='label' />
      </StringToLabelObject>
    </SingleFieldList>
  </ArrayField>
)

ArrayStringField.styles = {
  SingleFieldList: makeStyles({
    root: {
      flexWrap: 'nowrap',
      maxWidth: '20em',
      overflowX: 'auto',
    },
  }),
}

export default ArrayStringField

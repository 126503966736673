import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import TypeInput from '../../components/TypeInput'
import LoadingInput from '../../components/LoadingInput'
import OptionsInput from '../../components/OptionsInput'

const Edit = ({ ...props }) => (
  <EditGuesser {...props}>
    <InputGuesser source={'licenseNumber'} />
    <TypeInput source={'type'} />
    <LoadingInput source={'loading'} />
    <OptionsInput source={'options'} />
  </EditGuesser>
)

export default Edit

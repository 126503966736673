import React from 'react'
import { CreateGuesser, InputGuesser } from '@api-platform/admin'
import { AutocompleteInput, ReferenceInput } from 'react-admin'
import FilterCompaniesByName from "../../components/filters/FilterCompaniesByName";

const filterToQuery = searchText => ({ departmentName: `${searchText}` });

const Create = ({ ...props }) => (
  <CreateGuesser {...props}>
    <ReferenceInput source="company" reference="companies" filterToQuery={FilterCompaniesByName} label="Compagnie">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="department" reference="departments" filterToQuery={filterToQuery} label="Département">
      <AutocompleteInput optionText="departmentName" />
    </ReferenceInput>
    <InputGuesser source={"meanPrice"} label="Coût moyen" />
  </CreateGuesser>
)

export default Create

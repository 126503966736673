import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'

const FreightForwarder = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='freight_forwarders'
    icon={ContactPhoneIcon}
    list={List}
    edit={Edit}
  />
)

export default FreightForwarder

import React from 'react'
import { CreateGuesser, InputGuesser } from '@api-platform/admin'
import { AutocompleteInput, BooleanInput } from 'react-admin'
import ReferenceInput from "../../components/ReferenceInput";
import FilterCompaniesByName from "../../components/filters/FilterCompaniesByName";

const CreateCompanyCustomPage = ({...props}) => (
    <CreateGuesser {...props} resource={'company_custom_pages'}>
        <BooleanInput source={'enabled'} label={"Activé ?"}/>
        <InputGuesser source={'slug'} label={"Sous-domaine"} fullWidth/>

        <ReferenceInput source='company' reference="companies" filterToQuery={FilterCompaniesByName}  label="Compagnie" fullWidth>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </CreateGuesser>
)

export default CreateCompanyCustomPage

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import FlightLandIcon from '@material-ui/icons/FlightLand'
import Create from "./Create";

const Rides = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='rides'
    icon={FlightLandIcon}
    list={List}
    create={Create}
    edit={Edit}
  />
)

export default Rides

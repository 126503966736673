import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import CommuteIcon from '@material-ui/icons/Commute'

const Vehicles = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='vehicles'
    icon={CommuteIcon}
    list={List}
    edit={Edit}
  />
)

export default Vehicles

import React from 'react'
import { SelectInput } from 'react-admin'

const TypeInput = (props) => (
  <SelectInput
    {...props}
    source='type'
    choices={[
      { id: 't1', name: 'T1' },
      { id: 't2', name: 'T2' },
      { id: 't3', name: 'T3' }
    ]}
  />
)

export default TypeInput

import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'

const IndependentCourier = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='independent_couriers'
    icon={DirectionsRunIcon}
    list={List}
    edit={Edit}
  />
)

export default IndependentCourier

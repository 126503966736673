import React from 'react'
import {CreateGuesser, InputGuesser} from '@api-platform/admin'
import PriceInput from '../../components/PriceInput'
import TailgateGuaranteedInput from '../../components/TailgateGuaranteedInput'

const Create = ({...props}) => (
    <CreateGuesser {...props}>
        <InputGuesser source={'name'} label={"Nom"}/>
        <InputGuesser source={'description'}/>

        <InputGuesser source={'allowance1MaxMileage'} label={"Distance 1 km max"}/>
        <PriceInput source={'allowance1FlatPrice'} label={"Distance 1 montant"}/>

        <InputGuesser source={'allowance2MaxMileage'} label={"Distance 2 km max"}/>
        <PriceInput source={'allowance2FlatPrice'} label={"Distance 2 montant"}/>

        <InputGuesser source={'allowance3MaxMileage'} label={"Distance 3 km max"}/>
        <PriceInput source={'allowance3FlatPrice'} label={"Distance 3 montant"}/>

        <InputGuesser source={'allowance4MaxMileage'} label={"Distance moyenne km max"}/>
        <InputGuesser source={'allowance4Rate'} label={"Distance moyenne taux km"}/>

        <InputGuesser source={'allowance5MaxMileage'} label={"Longue distance km max"}/>
        <PriceInput source={'allowance5FlatPrice'} label={"Longue distance montant"}/>

        <InputGuesser source={'allowance6Rate'} label={"Très longue distance taux km"}/>
        <PriceInput source={'allowance6CeilingPrice'} label={"Très longue distance montant plafond"}/>

        <PriceInput source={'flatPrice'} label={"Forfait fixe (prix unique)"}/>

        <InputGuesser source={'pickupTimeLimit'} label={"Temps limite pour prix garanti (minutes)"}/>

        <TailgateGuaranteedInput source={'tailgateGuaranteed'} label={"Prix garanti avec hayon"}/>

        <InputGuesser source={'notWorkingDayRate'} label={"Taux pour les jours non ouvrés"}/>
    </CreateGuesser>
)

export default Create

const RIDE_TYPE_SINGLE = 'single'
const RIDE_TYPE_RECURRENT = 'recurrent'
const RIDE_TYPE_GLINKO_LOADING = 'glinko loading'
const RIDE_TYPE_GLINKO_DELIVERY = 'glinko delivery'

export const RIDE_TYPES = {
    [RIDE_TYPE_SINGLE]: 'Ponctuel',
    [RIDE_TYPE_RECURRENT]: 'Récurrent',
    [RIDE_TYPE_GLINKO_LOADING]: 'Chargement Glinko',
    [RIDE_TYPE_GLINKO_DELIVERY]: 'Course Glinko',
}

export const rideTypesList = [
    {id: RIDE_TYPE_SINGLE, name: RIDE_TYPES[RIDE_TYPE_SINGLE]},
    {id: RIDE_TYPE_RECURRENT, name: RIDE_TYPES[RIDE_TYPE_RECURRENT]},
    {id: RIDE_TYPE_GLINKO_LOADING, name: RIDE_TYPES[RIDE_TYPE_GLINKO_LOADING]},
    {id: RIDE_TYPE_GLINKO_DELIVERY, name: RIDE_TYPES[RIDE_TYPE_GLINKO_DELIVERY]},
]

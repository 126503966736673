import React, { useState, useCallback, useMemo } from 'react'
import {
  ReferenceInput as RAReferenceInput,
  AutocompleteInput as RAAutocompleteInput,
  ReferenceArrayInput as RAReferenceArrayInput,
  AutocompleteArrayInput as RAAutocompleteArrayInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const ReferenceInput = ({ reference, optionText = 'name', isArray = false, required, ...props }) => {
  const classes = useStyles()
  // Dirty af, but recommended by react-admin, force to reload the available data
  const [key, setKey] = useState(0)
  /* eslint-disable-next-line no-unused-vars */
  const handleChange = useCallback(() => setKey(key + 1), [key])

  const ReferenceInput = useMemo(() => isArray ? RAReferenceArrayInput : RAReferenceInput, [isArray])
  const AutocompleteInput = useMemo(() => isArray ? RAAutocompleteArrayInput : RAAutocompleteInput, [isArray])

  return (
    <div className={classes.root}>
      <ReferenceInput key={key} reference={reference} allowEmpty {...props} isRequired={required}>
        <AutocompleteInput optionText={optionText} />
      </ReferenceInput>
    </div>
  )
}

export default ReferenceInput
